import AOS from "aos";
import contacto from "./contacto";
import medios from "./forma-proveedor-medios";

/*
-------------------------------
Menu movil
-------------------------------
*/
document.addEventListener("DOMContentLoaded", function () {
	const hamburger = document.querySelector(".hamburger");
	const menuOverlay = document.querySelector(".menu-overlay");

	hamburger.addEventListener("click", (e) => {
		e.preventDefault();
		hamburger.classList.toggle("is-active");
		menuOverlay.classList.toggle("is-active");
	});
});

/*
-------------------------------
Animaciones scroll
-------------------------------
*/
AOS.init();

var scrollRef = 0;
window.addEventListener("scroll", function () {
	// increase value up to 10, then refresh AOS
	if (scrollRef <= 10) {
		scrollRef++;
	} else {
		AOS.refresh();
	}
});

/*
-------------------------------
Agenda en footer
-------------------------------
*/
document.addEventListener("DOMContentLoaded", function () {
	const agendaBtn = document.querySelector("#formaAgendaFooter button");
	const agendaEmail = document.querySelector(
		"#formaAgendaFooter #emailAgenda"
	);

	agendaBtn.addEventListener("click", function (e) {
		e.preventDefault();
		const mail = agendaEmail.value;
		if (mail === "") {
			agendaEmail.classList.add("is-invalid");
		} else {
			window.location.href = `contacto.html?e=${mail}#agendaFormaC`;
		}
	});
});

/*
-------------------------------
Contacto
-------------------------------
*/
contacto();
medios();
