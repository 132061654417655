import validator from "validator";
import jCaptcha from "js-captcha";

const medios = () => {
	document.addEventListener("DOMContentLoaded", function () {
		let formaData = null;

		const formularioMedios = document.querySelector(
			"#formularioProveedorMedios"
		);

		// Si existe el formulario
		if (formularioMedios) {
			// Obtener valores de los campos del formulario
			function getFormaMedios() {
				const elements = document.getElementById(
					"formularioProveedorMedios"
				).elements;
				let obj = {};
				for (let i = 0; i < elements.length; i++) {
					let item = elements.item(i);
					obj[item.name] = item.value;
				}
				formaData = obj;
			}

			// Init captcha
			let captcha = false;

			let myCaptcha = new jCaptcha({
				el: ".jCaptchaMedios",
				canvasClass: "jCaptchaCanvasMedios",
				canvasStyle: {
					// required properties for captcha stylings:
					width: 100,
					height: 15,
					textBaseline: "top",
					font: "16px Arial",
					textAlign: "left",
					fillStyle: "#ffffff",
				},
				// set callback function for success and error messages:
				callback: (response, $captchaInputElement, numberOfTries) => {
					if (response == "success") {
						captcha = true;
					}
					if (response == "error") {
						captcha = false;
						alert(
							"Por favor completa la prueba captcha correctamente."
						);
						document
							.querySelector(".jCaptchaMedios")
							.classList.add("is-invalid");
						return false;
					}
				},
			});

			function validarCamposFormaMedios(nombre, email, tel) {
				// nombre
				if (nombre === "" || nombre === null) {
					document
						.querySelector("#nombreM")
						.classList.add("is-invalid");
					return false;
				} else {
					document
						.querySelector("#nombreM")
						.classList.remove("is-invalid");
				}

				// tel
				if (tel === "" || tel === null) {
					document.querySelector("#telM").classList.add("is-invalid");
					return false;
				} else {
					document
						.querySelector("#telM")
						.classList.remove("is-invalid");
				}

				// email valido
				if (!validator.isEmail(email)) {
					document
						.querySelector("#emailM")
						.classList.add("is-invalid");
					return false;
				} else {
					document
						.querySelector("#emailM")
						.classList.remove("is-invalid");
				}

				// Captcha
				myCaptcha.validate();

				if (captcha === true) {
					// desahabilita el boton de enviar
					document
						.querySelector("#submitM")
						.setAttribute("disabled", true);

					// Obtener los datos de la forma para el envio
					const formaDatosParaEnviar = new FormData(
						document.getElementById("formularioProveedorMedios")
					);

					const url =
						"https://shotpruebas.com/tangarina/php/processMedios.php";

					// const data = formaDatosParaEnviar;
					const data = new FormData();
						data.append('nombre', nombre);
						data.append('email', email);
						data.append('tel', tel);

					const params = {
						body: data,
						method: "POST",
					};

					fetch(url, params)
						.then((data) => {
							return data;
						})
						.then((response) => {
							// console.log(response.status);
							if (response.status == 200) {
								location.href = "gracias.html";
							} else {
								console.log(response);
							}
						})
						.catch((error) => console.log(error));
				}
			}

			formularioMedios.addEventListener("submit", (e) => {
				e.preventDefault();
				getFormaMedios();
				validarCamposFormaMedios(
					formaData.nombreM,
					formaData.emailM,
					formaData.telM
				);
			});
		}
	});
};

export default medios;
